<template>
  <div class="terms_of_use">
    <h1><b>Terms Of Use</b></h1>
    <h4>Version October 2022</h4>
    <p>Welcome to Mih5! We (Mih5 B.V., a company established in the Netherlands - hereafter referred to as 'Mih5', 'we', 'our' or 'us') operate a website, accessible at <a href="https://mih5.com" target="_blank">https://mih5.com</a>. In this document we explainthe terms of use for our Mih5 Website. Please click this <a href="https://mih5.com" target="_blank">link</a> for an overview of the domains referred to as “Mih5 Website”. The Mih5 Website may include access to virtual environments, games and other content, as well as downloadable software or applications for use on personal computers, tablets, mobile devices or phones. All users of the Mih5 Website (“Website Visitors”) are subject to the following terms and conditions of use (these “Terms of Use”).</p>
    <p>Please read these Terms of Use carefully before accessing or using any part of this Mih5 Website. By accessing or using this Mih5 Website, you agree that you have read, understand and agree to be bound by these Terms of Use, as amended from time to time by Mih5. If you do not wish to agree to these Terms of Use, do not access or use any part of this Mih5 Website.</p>
    <p>Mih5 may revise and update these Terms of Use at any and if we do so, we will notify you by posting the revised Terms of Use on the Mih5 Website. Your continued use of the Mih5 Website means that you accept and agree to the revised Terms of Use. If you disagree with the Terms of Use or are dissatisfied with this Mih5 Website, your sole and exclusive remedy is to discontinue using this Mih5 Website.</p>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value = !value"><i v-if="value" class="el-icon-caret-right"></i><i v-if="!value" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">1. Use of this website</h2></div>
    <div v-if="!value">
      <p>You agree to use the Site only for lawful purposes:</p>
      <p>(a) Specifically you agree not to do any of the following: (1) upload to or transmit on the Mih5 Website any defamatory, indecent, obscene, harassing, violent or otherwise objectionable material, or any material that is, or may be, protected by copyright, without permission from the copyright owner; (2) use the Mih5 Website to violate the legal rights (including the rights of publicity and privacy) of others or to violate the laws of any jurisdiction; (3) intercept or attempt to intercept electronic mail not intended for you; (4) misrepresent an affiliation with any person or organization; (5) upload to or transmit on the Mih5 Website any advertisements or solicitations of business; (6) restrict or inhibit use of the Mih5 Website by others; (7) upload or otherwise transmit files that contain a virus or corrupted data; (8) collect information about others (including e-mail addresses) without their consent; (9) download a file or software or include in a message any software, files or links that you know, or have reason to believe, cannot be distributed legally over the Mih5 Website or that you have a contractual obligation to keep confidential (notwithstanding its availability on the Mih5 Website); (10) post “spam,” transmit chain letters or engage in other similar activities; (11) solicit, provide or exchange any personal information, including but not limited to user names or passwords; (12) “stalk,” “phish,” abuse or harass another user, or attempt to do any of the foregoing; or (13) engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Mih5 Website, or which, as determined by Mih5, may harm Mih5 or Website Visitors or expose them to liability. Without limiting any of the foregoing, you also agree to abide by any code of conduct and policies applicable to the Mih5 Website or any service available on the Mih5 Website.</p>
      <p>(b) Any content and/or opinions uploaded, expressed or submitted to a message board, blog, chatroom or any other publicly available section of the Mih5 Website (including password-protected areas), and all articles and responses to questions, other than the content provided by Mih5, are solely the opinions and responsibility of the person or entity submitting them and do not necessarily reflect the opinions of Mih5. You understand and acknowledge that you are responsible for whatever content you submit, and you, not Mih5, have full responsibility for such content, including its legality, reliability and appropriateness. By uploading or otherwise transmitting material to any area of the Mih5 Website, you warrant that the material is your own or is in the public domain or otherwise free of proprietary or other restrictions and that you have the right to post it to the Mih5 Website. You grant to Mih5 the royalty-free, irrevocable, perpetual, transferable and world-wide right and license to use all content you upload or otherwise transmit to the Mih5 Website in any reasonable manner Mih5 chooses, including, but not limited, to copying, displaying, performing or publishing it in any format or media whatsoever, modifying it, incorporating it into other material or making a derivative work based on it.</p>
      <p>(c) Except as expressly authorized by Mih5 in writing, you may not reproduce, sublicense, distribute, sell or exploit for any commercial purposes (i) any part of this Mih5 Website or its content, (ii) access to this Mih5 Website or (iii) use of this Mih5 Website or of any services or materials available through  this Mih5 Website, including, without limitation, by leasing access to the Mih5 Website (e.g., at a cyber café), gathering and selling virtual items, codes, pre-paid game cards, or virtual currency through the Mih5 Website, or otherwise.</p>
      <p>(d) Mih5 reserves the right, but does not assume any responsibility, to (1) remove any material posted on the Mih5 Website which Mih5, in its sole discretion, deems inconsistent with the foregoing commitments (including any material that Mih5 has reason to believe constitutes, or for which Mih5 has received notice of its constituting, a copyright infringement; or if the material is in breach with the applicable data protection laws and regulations); (2) monitor and/or record communications between and among Website Visitors for as far as allowed under the applicable law; and (3) terminate any user's access to all or part of the Mih5 Website. However, Mih5 can neither review all material or communication before it is posted on the Mih5 Website nor ensure prompt removal of objectionable material after it has been posted. Accordingly, Mih5 assumes no liability for any action or inaction regarding transmissions, communications or content provided by third parties. Mih5 reserves the right to take any action it deems necessary to protect the personal safety of Website Visitors and the public; however, Mih5 has no liability or responsibility to anyone for performance or non-performance of the activities described in this paragraph.</p>
      <p>(e) Your failure to comply with the provisions of (a), (b) or (c) above may result in the termination of your access to the Mih5 Website and may expose you to civil and/or criminal liability.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value2 = !value2"><i v-if="value2" class="el-icon-caret-right"></i><i v-if="!value2" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">2. Privacy: protection of personal information</h2></div>
    <div v-if="!value2">
      <p>Mih5's use of your personal information and your responsibilities in connection with protecting your privacy are described our <a href="https://mih5.com" target="_blank">Website Privacy Statement Policy</a>.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value3 = !value3"><i v-if="value3" class="el-icon-caret-right"></i><i v-if="!value3" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">3. Uploads to your devices</h2></div>
    <div v-if="!value3">
      <p>Please note that if you are using downloadable applications from Mih5, updates to your device's systems or firmware may render your use of the applications incompatible. Mih5 does not warrant that the Mih5 Website or any Mih5 applications will be compatible with any updates to, or prior versions of, your devices. Mih5 may, but is not obligated to, provide you with updates to the Mih5 Website or applications that improve compatibility with updated mobile devices.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value4 = !value4"><i v-if="value4" class="el-icon-caret-right"></i><i v-if="!value4" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">4. Data charges</h2></div>
    <div v-if="!value4">
      <p>To the extent that your use of the Mih5 Website or any Mih5 application requires, or permits utilization of, wireless, cellular data, or internet access, you are independently responsible for securing the necessary data access service. For example, with respect to your mobile devices, the provider of your data plans may charge you data access fees in connection with your use of the Mih5 Website or Mih5 applications. You are solely responsible for all such charges payable to third parties.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value5 = !value5"><i v-if="value5" class="el-icon-caret-right"></i><i v-if="!value5" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">5. Copyright restrictions/use of content</h2></div>
    <div v-if="!value5">
      <p>The entire contents of this Mih5 Website (including all information, text, displays, images and audio and any software made available through or in connection with the Mih5 Website) and the design, selection and arrangement thereof, are proprietary to Mih5 or its affiliates or licensors and are protected by Dutch and international laws regarding copyrights, trademarks, trade secrets and other proprietary rights. You are authorized only to use the content on the Mih5 Website for personal use related to your role as a current or prospective user of the Mih5 Website. You may not copy, modify, create derivative works of, publicly display or perform, republish, store, transmit or distribute any of the material on this Mih5 Website without the prior written consent of Mih5, except to: (a) store copies of such materials temporarily in RAM, (b) store files that are automatically cached by your web browser for display enhancement purposes, and (c) print a reasonable number of pages of the Mih5 Website; provided in each case that you do not alter or remove any copyright or other proprietary notices included in such materials. Neither the title nor any intellectual property rights to any information or material in this Mih5 Website are transferred to you, but remain with Mih5 or the applicable owner of such content.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value6 = !value6"><i v-if="value6" class="el-icon-caret-right"></i><i v-if="!value6" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">6. Software and downloads</h2></div>
    <div v-if="!value6">
      <p>If Mih5 offers downloads of, or access to, software on this Mih5 Website and you download or otherwise access such software, the software (including any data or images incorporated in or generated by the software) is licensed to you. You do not receive title to this software and you may not distribute or use the software other than for the purpose of using the applicable feature or service of the Mih5 Website as offered by Mih5. You may not modify, adapt, reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of such software. Except as expressly provided, you may not create any derivative works of the software or any services available on the Mih5 Website. This license is revocable at any time without notice and with or without cause. You agree to destroy or return to Mih5 all copies of the software upon revocation of your license to the software and/or termination of your access to the Mih5 Website. The software is subject to all restrictions on use, disclaimers of warranties and other provisions in these Terms of Use. In the event that the software is also subject to a separate end user license agreement, the terms of such end user license agreement shall control any conflict between those terms and these Terms of Use.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value7 = !value7"><i v-if="value7" class="el-icon-caret-right"></i><i v-if="!value7" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">7. Access and interference</h2></div>
    <div v-if="!value7"><p>You agree that you will not (a) use any robot, spider or other automatic device, process or means to access the Mih5 Website, (b) use any manual process to monitor or copy any of the material on this Mih5 Website or for any other unauthorized purpose without the prior written consent of Mih5, (c) use any device, software or routine that interferes with the proper working of the Mih5 Website, (d) attempt to interfere with the proper working of the Mih5 Website, (e) take any action that imposes an unreasonable or disproportionately large load on Mih5's infrastructure, or (f) access, reload or “refresh” transactional pages, or make any other request to transactional servers, more than once during any three (3) second interval.</p></div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value8 = !value8"><i v-if="value8" class="el-icon-caret-right"></i><i v-if="!value8" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">8. Trademarks</h2></div>
    <div v-if="!value8"><p>Mih5's name and logos, and all related names, logos, product and service names, designs and slogans contained in the Mih5 Website or any software provided or accessed in connection therewith are trademarks of Mih5, its affiliates, licensors and/or contractors unless otherwise clearly specified in writing. You may not use such marks without the prior written permission of Mih5. All other names, brands and marks are used for identification purposes only and may be the trademarks of their respective owners.</p></div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value9 = !value9"><i v-if="value9" class="el-icon-caret-right"></i><i v-if="!value9" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">9. Liability of Mih5 and its licensors</h2></div>
    <div v-if="!value9">
      <p>Mih5 does not assume any liability for the materials, information and opinions provided on, or available through, the Mih5 Website (the “Mih5 Website Content”). Reliance on the Mih5 Website Content is solely at your own risk. Mih5 disclaims any liability for injury or damages resulting from the use of the Mih5 Website and any Mih5 Website Content.</p>
      <p>The website is provided “as is” and “as available,” without any warranty or guaranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Mih5, its affiliates and service providers are neither responsible nor liable for any indirect, incidental, consequential, special, exemplary, punitive or other damages arising out of or relating in any way to the website. Mih5 shall not be liable under these Terms of Use or otherwise in connection with the website for an amount more than EUR 500 (five hundred euros).</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value10 = !value10"><i v-if="value10" class="el-icon-caret-right"></i><i v-if="!value10" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">10. Interaction with other users</h2></div>
    <div v-if="!value10"><p>As a condition of access to the Mih5 Website, you release Mih5 (and its shareholders, partners, affiliates, directors, officers, subsidiaries, employees, agents, suppliers, third party information providers, licensors, licensees, distributors and contractors) from claims, demand and damages (actual and consequential) of every kind and nature arising out of or in any way connected with any dispute you may have with any other user of the Mih5 Website. Mih5 will have the right but not the obligation to resolve disputes between Website Visitors relating to use of the Mih5 Website, and to the extent that it elects to resolve such disputes, Mih5 will do so in good faith Mih5 Website. You release Mih5 (and its shareholders, partners, affiliates, directors, officers, subsidiaries, employees, agents, suppliers, third party information providers, licensors, licensees, distributors and contractors) from claims, demands and damages (actual and consequential) of every kind and nature arising out of or in any way connected with Mih5's resolution of such disputes.</p></div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value11 = !value11"><i v-if="value11" class="el-icon-caret-right"></i><i v-if="!value11" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">11. Indemnification</h2></div>
    <div v-if="!value11"><p>You agree to indemnify and hold harmless Mih5 and its shareholders, partners, affiliates, directors, officers, subsidiaries, employees, agents, suppliers, third party information providers, licensors, licensees, distributors, contractors and others involved in the Mih5 Website or the delivery of products, services or information over the Mih5 Website, from and against any and all liabilities, expenses, damages and costs, including reasonable attorney's fees, arising from any violation by you of these Terms of Use or your use of the Mih5 Website or any products, services or information obtained from the Mih5 Website.</p></div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value12 = !value12"><i v-if="value12" class="el-icon-caret-right"></i><i v-if="!value12" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">12. Comments and submissions</h2></div>
    <div v-if="!value12"><p>Mih5 welcomes your comments. All comments, suggestions or other information sent by you to Mih5 or its advertisers or business partners in response to solicitations on this Mih5 Website will become Mih5's property and you agree that all intellectual property rights therein are herewith transferred in advance to Mih5. For avoidance of doubt, Mih5 shall own any developments by Mih5 or on its behalf arising out of your comments, suggestions or other submissions. To the extent Mih5 does not own such materials, you grant and agree to grant Mih5 a non-exclusive, royalty-free, worldwide license to utilize, create derivative works of, distribute and sublicense such materials for any purpose in connection with Mih5's web Mih5 Websites, products and services. You have no expectation of any review, compensation or consideration of any type for all submissions hereunder.</p></div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value13 = !value13"><i v-if="value13" class="el-icon-caret-right"></i><i v-if="!value13" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">13. Links to other websites</h2></div>
    <div v-if="!value13"><p>The Mih5 Website contains links to other Mih5 Websites on the Internet. Mih5 is not responsible for, and does not endorse, the content, products, services or practices of any third-party websites. This includes, without limitation, websites framed within the Mih5 Website, as well as third-party advertisements. Mih5 does not make any representations regarding the quality, content, accuracy or suitability for your viewing or use of these third-party websites and third-party advertisements. Your use of third-party websites is at your own risk and subject to the terms and conditions of use of such a website. In the event that you choose to purchase a product or service from a third party, Mih5 is not responsible for such products or services, as it is not party to such transaction and is not liable for any direct or indirect costs or damages arising out of any dispute between you and such third party. Neither Mih5, its licensors or contractors, makes any express or implied representations or warranties regarding the goods or services offered by such merchant, including, but not limited to, warranties of merchantability, fitness for a particular purpose, title, non-infringement or compatibility.</p></div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value14 = !value14"><i v-if="value14" class="el-icon-caret-right"></i><i v-if="!value14" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">14. Copyright and other intellectual property infringement</h2></div>
    <div v-if="!value14">
      <p>Mih5 may, in appropriate circumstances and in Mih5's sole discretion, terminate your access to this Mih5 Website if it deems you to be a repeat infringer of intellectual property rights connected to the Mih5 Website. Mih5 may also, in its sole discretion, limit your access to the Mih5 Website and/or terminate your membership if you infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
      <p>Please contact our designated agent at <b>fungames@foxmail.com</b> if you become aware of any content that may infringe the copyright, other intellectual property right or privacy rights of a third party or that you believe to be in violation of these Terms of Use.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value15 = !value15"><i v-if="value15" class="el-icon-caret-right"></i><i v-if="!value15" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">15. Choice of law</h2></div>
    <div v-if="!value15">
      <p>These Terms of Use shall be governed in all respects by and construed in accordance with the laws of the Netherlands, without regard to its conflicts of law principles. If your compliance with or our rights under these Terms of Use is/are in any way affected by consumer protection laws in your country of residence, you should not use our Mih5 Website.</p>
      <p>Any dispute arising out of or in connection with these Terms of Use or your use of the Mih5 Website, shall be submitted to the competent court of Amsterdam, the Netherlands. If you are a resident in a member state of the EU or a country in which this clause is prohibited by local law, this clause does not apply to you and does not deprive you of the protection of the mandatory provisions of the consumer protection laws in your country.</p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value16 = !value16"><i v-if="value16" class="el-icon-caret-right"></i><i v-if="!value16" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">16. Miscellaneous</h2></div>
    <div v-if="!value16">
      <p>These Terms of Use, as they may be amended from time to time, completely and exclusively state the agreement between you and Mih5 with respect to the Mih5 Website, and no other terms that may have been communicated to you orally or in any other manner shall have any force or effect. Any cause of action you may have with respect to the Mih5 Website must be commenced within one (1) year after the claim or cause of action arises or such claim or cause of action is barred.</p>
      <p>You agree and acknowledge that your violation of any restrictions in these Terms of Use on the use of the Mih5 Website, Mih5 Website Content, or software or services available on or through the Mih5 Website, or your use or disclosure of confidential information in a manner inconsistent with the provisions of these Terms of Use, may cause Mih5 irreparable damage for which remedies at law may be inadequate. Mih5's licensors and contractors are express third-party beneficiaries of any terms in these Terms of Use that are applicable to their products or services, including disclaimers of warranty and limitations of liability, and shall have the right to enforce directly against you all of your applicable representations, warranties, covenants, indemnifications and obligations under these Terms of Use.</p>
      <p>If any part of these Terms of Use is unenforceable, the unenforceable part shall be construed to reflect, as nearly as possible, the original intentions of the parties. The other provisions of these Terms of Use shall remain in full force and effect.</p>
      <p>The terms related to protection of Mih5's intellectual property rights, disclaimer of warranties limitation of liability, choice of law, indemnification obligations and any licenses granted by you to Mih5 shall survive any termination of these Terms of Use.</p>
      <p>Mih5's failure to insist upon or enforce strict performance of any provision of these Terms of Use shall not constitute a waiver of the provision. Neither a course of dealing or conduct between you and Mih5 nor any trade practices shall be deemed to modify these Terms of Use.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  data() {
    return {
      value: true,
      value2: true,
      value3: true,
      value4: true,
      value5: true,
      value6: true,
      value7: true,
      value8: true,
      value9: true,
      value10: true,
      value11: true,
      value12: true,
      value13: true,
      value14: true,
      value15: true,
      value16: true,
    }
  }
}
</script>

<style lang="less" scoped>
.terms_of_use{
  width: 100%;
  height: 100vh;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
  h1{
    font-size: 24px;
    line-height: 1.875;
    margin: 0px 0px 0.5em;
  }
  h4{
    font-size: 16px;
    line-height: 1;
    color: #5d6b84;
    margin: 1em 0px;
  }
  h2{
    font-size: 20px;
    line-height: 1.25;
    color: #009cff;
    margin: 1em 0px;
  }
  h3{
    margin: 1em 0;
  }
  p{
    margin: 1em 0px;
    color: #002b50;
  }
  a{
    text-decoration: none;
    color: #009cff;
  }
  ul,ol{
    margin: 0.5em 0px;
    li{
      margin-left: 40px;
    }
  }
}
</style>