<template>
  <div class="cookie_statement">
    <h1><b>Global Cookie Statement</b></h1>
    <h4>Version October 2022</h4>
    <h2>1.   General information</h2>
    <p>
      Mih5 is committed to protecting the privacy of everyone who uses our websites and applications. This cookie statement - the "Global Cookie Statement", or in short "Cookie Statement" - now applies to all countries outside the EU and UK. It is designed to provide transparency into Mih5's privacy practices and principles. It provides information on the data that we collect from our website visitors and sets out how cookies and similar technologies are used via
      <a href="https://mih5.com" target="_blank">Mih5.com</a> and <a href="https://mih5.com" target="_blank">these alternative domains</a> (the "Mih5 Website"). In addition, some background information on cookies and how you can manage your cookie settings, is provided.
    </p>
    <p>
      Mih5 B.V. ("Mih5", "we", "our" or "us") is involved with and responsible for the processing of your data via the Mih5 Website. For more information on how Mih5 handles your data in this regard and your privacy rights, please refer to Mih5's
      <a href="https://mih5.com" target="_blank">Privacy Statement</a>.
    </p>
    <p>
      Please note that Mih5 may update this Cookie Statement from time to time. If Mih5 makes important changes, we will let you know. You can of course also check this Cookie Statement to ensure that you are aware of the most updated version.
    </p>
    <p>
      <i>* Please note that we determine from which region you visit our website based on your IP-address, thus if you use a VPN-connection, you may not be shown the Cookie Statement applicable to you.</i>
    </p>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value = !value"><i v-if="value" class="el-icon-caret-right"></i><i v-if="!value" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">2.   What are cookies?</h2></div>
    <div v-if="!value">
      <p><b>Cookies.</b> Cookies are small bits of data that can be placed on your computer, tablet, smartphone or other electronic "device" with which you can use the internet via a web browser. When a website is visited, the website can place these cookies on your device via your web browser. Examples of web browsers are: Microsoft Edge, Firefox, Safari, Google Chrome and Opera.</p>
      <p>There are two different categories of cookies:</p>
      <ol type="a">
        <li><u>First party cookies.</u>Cookies which Mih5 places on your device for its own purposes.</li>
        <li><u>Third party cookies.</u> Cookies placed by or for an external/third party (e.g. other company). Usually these are cookies for the purpose of advertisement and tracking (see below). Vice versa, Mih5 may also obtain cookie information obtained by third parties when you visit other websites.</li>
      </ol>
      <p><b>Purpose cookies.</b> After a cookie is placed on your device, your device can be recognized as you browse through the Mih5 Website via the same web browser. This will for instance enable the Mih5 Website to ‘remember' your language settings, which games you played and your progress in these games. If these cookies are placed by a third-party, such third-party can (also) use the cookie for the same purposes.</p>
      <p>For more information on cookies, please refer to the website <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a>.</p>
      <p><b>Lifecycle cookies.</b> After a cookie is placed on a device, it is only valid for a certain period.</p>
      <ol>
        <li><u>Default period.</u> After the validity period of a cookie passes, the cookie is automatically removed by the web browser. For local storage:Mih5 will make sure that it is removed after the time specified in the
          <a href="https://mih5.com" target="_blank">Cookie Table</a>.
          <p>The validity period varies per cookie. For example, some cookies are only valid during the browser session. Other cookies can also recognize your device if you visit the Mih5 Website during a new browser session, e.g. for half a year after being placed.</p>
        </li>
        <li><u>Early deletion/refusal of cookies. </u>You can choose to delete a cookie manually before its validity period has passed. You can also decide to refuse the cookies which may only be used with your consent. For more information on this, please see the section below: "How can you manage cookies and cookie settings?".</li>
      </ol>
      <p><b>Similar technologies.</b> In addition to cookies, other techniques can also be used for similar purposes, such as:</p>
      <ol>
        <li><u>HTML5 Local Storage.</u> HTML5 Local Storage and local shared objects can be used for the same purposes as cookies.</li>
        <li><u>Web beacons.</u> Web beacons (also called "tags" or "tracking pixels") are bits of programming code that can be included in web pages, emails, and ads. Web beacons can notify the user when those web pages, emails, or ads have been viewed or clicked on by you.</li>
      </ol>
      <p>
        Below, when we speak of "cookies", we also mean the similar technologies described above, unless we say otherwise.
      </p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value2 = !value2"><i v-if="value2" class="el-icon-caret-right"></i><i v-if="!value2" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">3.   What type of cookies do we use?</h2></div>
    <div v-if="!value2">
      <p>Mih5 uses three types of cookies on the Mih5 Website: (1) functional cookies, (2) analytical cookies and (3) advertising cookies.</p>
      <h3>(i) Functional cookies.</h3>
      <ol>
        <li><u>Definition:</u> Functional cookies are essential for the proper layout and navigation of a website. It concerns cookies (1) that are placed with the sole purpose of facilitating communication over an electronic communication network or (2) that are strictly necessary for providing you with the information-society service as requested by you.</li>
        <li><u>No consent required:</u> Depending on the applicable law, functional cookies may be placed without your consent. You can object to these cookies via your browser settings (see below).</li>
      </ol>
      <h3>(ii) Analytical cookies.</h3>
      <ol>
        <li><u>Definition analytical cookies:</u> Analytical cookies are used to analyze and map the use of a certain website, so that the quality and/or effectiveness of Mih5's services can be improved.</li>
        <li><u>Not always consent required:</u> Depending on the applicable law, Mih5 does not need your consent to place cookies for obtaining information about the quality or effectiveness of a provided information-society service (analytical cookies), if this will have little or no impact on your private life. In certain jurisdictions - for example the member states of the EU - we need your consent for the use of certain analytical cookies. If required, we will ask for your consent.</li>
      </ol>
      <h3>(iii) Advertising cookies.</h3>
      <ol>
        <li><u>Definition advertising cookies:</u> Advertising/targeting cookies are cookies which are used to analyze user activity and sessions and allows parties to deliver a more personalized experience. Advertising cookies are mainly set by the third parties with whom Mih5 runs advertising campaigns. This enables Mih5 to serve ads that are relevant to you.</li>
        <li><u>Consent required:</u> Depending on the applicable law, we may need your consent to use advertising cookies. This is for instance case within the member states of the EU. If required, we will ask for your consent to place advertising cookies.</li>
      </ol>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value3 = !value3"><i v-if="value3" class="el-icon-caret-right"></i><i v-if="!value3" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">4.   Who places which cookies?</h2></div>
    <div v-if="!value3">
      <h3>(i) Mih5 cookies</h3>
      <p>Some cookies are placed directly by Mih5 or on behalf of Mih5. This concerns all functional cookies, some analytical cookies and advertising cookies. A full overview of the cookies placed via your browser can be found via our Cookie Consent Module - if available in your region -, or via your browser's Developer Tools which you can open with ctrl+shift+i on Windows or cmd+shift+i on Mac.</p>
      <h3>(ii) Developer cookies</h3>
      <p>The developers of the games on the Mih5 Website, can also include cookies in their game. For example, to track how much points you earned during a game. Mih5 is not responsible for this processing: only the developer decides which cookie data is processed and for what purpose. However, because Mih5 feels it is important that your data is also safe when you play a game, we took two measures. In the first place, we included contractual safeguards in our contracts with the developers. In the second place, we've prepared
        <a href="https://mih5.com" target="_blank">this list</a> of the cookies used by our game developers, categorized per game, to inform you on behalf of the developers.* That way, you can determine based on the games you play, which cookies may be placed and whether you want to change your cookie settings because of that.</p>
      <p><b>Google Analytics Blockage</b> Please note that we do not use Google Analytics on any of our websites. However, it is possible that one of the game developers we work with tries to use Google Analytics for tracking in-game activities. We actively try to find these implementations and overwrite them for users from the EU. To opt out of being tracked by Google Analytics across all websites, please visit:
        <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">http://tools.google.com/dlpage/gaoptout</a>.</p>
      <p><i>* Please note that this list may not be complete, since randomized cookies that are placed with less than 100 website visitors are not included in the list. Furthermore, the cookies used by developers may change over time. Therefore, we automatically update this list daily.</i></p>
      <h3>(iii) Secondary domains</h3>
      <p>The Mih5 domain (Mih5.com) also hosts some secondary pages: developers.Mih5.com, app.Mih5.dev, about.Mih5.com, jobs.Mih5.com, and shop.Mih5.com. Mih5 does not place or use any cookies on these sub-domains, except from shop.Mih5.com. On shop.Mih5.com we place functional cookies. Please see “2. What are cookies?” and “3. What type of cookies do we use?” for more information about (functional) cookies. If Mih5 uses or places cookies on these secondary pages, Mih5 has ensured that via these domains no cookies that require consent under the applicable law are placed without your consent.</p>
      <h3>(iv) Externally hosted games and other websites</h3>
      <p>This Cookie Statement only applies to cookies that are used when you visit the Mih5 Website. Mih5 is in no way responsible for cookies that are placed by and for other parties when you visit another webpage, also not if you are referred to such website from the Mih5 Website. To enable you to make a conscious choice in this respect, we do include a banner if you play a game via our website that is hosted on a third-party website. This banner informs you that you are about to access a third-party website.</p>
      <p>If you want to know more about any of the cookies used via our website or the organizations we work with in this respect, please feel free to contact us via <b>fungames@foxmail.com</b></p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value4 = !value4"><i v-if="value4" class="el-icon-caret-right"></i><i v-if="!value4" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">5.   How can you manage cookies and cookie settings?</h2></div>
    <div v-if="!value4">
      <p><b>Browser settings.</b> For most internet browsers it is possible to adjust the browser settings in such a way that you receive a warning before a cookie is placed. In most cases it is also possible to configure your browser to refuse all cookies or only third-party cookies. Furthermore, you can delete cookies which are already placed. Please keep in mind that it is necessary to adjust these settings separately on every different browser and computer you use.</p>
      <p><b>Cookie settings website. </b>In certain jurisdictions - for example the member states of the EU and UK - we need your consent for the use of certain analytical cookies and/or advertising cookies. In case you are located in one of these jurisdictions, you can provide your consent or amend your cookie settings on the Mih5 Website via this icon:⚙️. There, you can for instance choose whether:</p>
      <ul>
        <li>Your precise geolocation and information about device characteristics can be used;</li>
        <li>Advertisement and content performance can be measured and can be personalized based on a profile;</li>
        <li>Data can be used to build or improve user experience, systems and software; and whether</li>
        <li>Cookies, device identifiers, or other information can be stored or accessed on your device for the purposes presented to you.</li>
      </ul>
      <p>
        Via the cookie settings, you can also change for which purposes your data obtained via cookies may exactly be used, and/or specify per organization whether they are allowed to use your data or not.
      </p>
      <p><b>Functioning of the Website.</b> If you choose to block or delete certain cookies, it is possible the Mih5 Website or functions on this website do not work properly anymore. Deleting or refusing cookies also does not mean you will no longer see advertisements. However, as a result of disabling cookies, the advertisements shown may be less relevant to you.</p>
      <p><b>How to change or delete cookie settings in your browser.</b> For your convenience we included links on how to change cookie settings on the most used internet browsers:</p>
      <p>
        <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en" target="_blank">How to change cookies setting in Google Chrome</a>
      </p>
      <p>
        <a href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/edge" target="_blank">How to change cookies setting in Microsoft Edge</a>
      </p>
      <p><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">How to change cookies setting in Safari</a></p>
      <p><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer#w_cookie-settings" target="_blank">How to change cookies setting in Mozilla Firefox</a></p>
      <p><a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">How to change cookies setting in Opera</a></p>
      <p><a href="https://help.vivaldi.com/desktop/privacy/cookies/" target="_blank">How to change cookies setting in Vivaldi</a></p>
      <p><a href="https://browser.yandex.com/help/personal-data-protection/cookies.html#what" target="_blank">How to change cookie settings in Yandex</a></p>
      <p><b>More information.</b> Mih5 cares about your privacy! Please consider carefully which web browser you use. Some browsers may benefit from sharing your personal data, while others are completely independent. We advise you to research the available web browsers and decide for yourself which browser best suits your interests. Please visit <a href="http://www.whatismybrowser.com" target="_blank">www.whatismybrowser.com</a> for information on the browser you use and more information on other browsers and browser settings.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieStatement",
  data() {
    return {
      value: true,
      value2: true,
      value3: true,
      value4: true,
    }
  }
}
</script>

<style lang="less" scoped>
.cookie_statement{
  width: 100%;
  height: 100vh;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
  h1{
    font-size: 24px;
    line-height: 1.875;
    margin: 0px 0px 0.5em;
  }
  h4{
    font-size: 16px;
    line-height: 1;
    color: #5d6b84;
    margin: 1em 0px;
  }
  h2{
    font-size: 20px;
    line-height: 1.25;
    color: #009cff;
    margin: 1em 0px;
  }
  h3{
    margin: 1em 0;
  }
  p{
    margin: 1em 0px;
    color: #002b50;
  }
  a{
    text-decoration: none;
    color: #009cff;
  }
  ul,ol{
    margin: 0.5em 0px;
    li{
      margin-left: 40px;
    }
  }
}
</style>