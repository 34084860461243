<template>
  <div id="app">
<!--    <div v-if="determinePcOrMove == 2">-->
<!--      <Navigation v-if="navHide"></Navigation>-->
<!--      <router-view></router-view>-->
<!--&lt;!&ndash;      <BottomNav v-if="navHide"></BottomNav>&ndash;&gt;-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      <router-view></router-view>-->
<!--    </div>-->
    <router-view></router-view>
    <div class="sc-1ri0y0w-7 lFKjX">
      <div class="sc-1ri0y0w-2 fzkKCz" :style="svgBg"></div>
    </div>
    <div class="permission_prompt">
      <el-dialog
          :visible.sync="dialogVisible"
          :show-close="false"
          :close-on-click-modal="false"
          width="830px">
        <span>
          We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. Alternatively you may access more detailed information and change your preferences before consenting or to refuse consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to this website only. You can change your preferences at any time by returning to this site or visit our privacy policy.
        </span>
        <span slot="footer">
          <span class="dialog-footer">
            <el-button @click="moreClick">MORE OPTIONS</el-button>
            <el-button type="primary" @click="agreeClick">AGREE</el-button>
          </span>
          <span class="footer_text"><span @click="privacyClick">Privacy Statement</span> <span class="dian"></span> <span @click="cookieClick">Cookie Statement</span> <span class="dian"></span> <span @click="termsClick">Terms of Use</span></span>
        </span>
      </el-dialog>
      <el-dialog
          :visible.sync="secondaryDialogVisible"
          :show-close="false"
          :close-on-click-modal="false"
          custom-class="secondaryDialog"
          width="830px">
        <span>
          We and our partners store or access information on devices, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for the purposes described below. You may click to consent to our and our partners’ processing for such purposes. Alternatively, you may click to refuse to consent, or access more detailed information and change your preferences before consenting. Your preferences will apply to this website only. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. You can change your preferences at any time by returning to this site or visit our privacy policy.
        </span>
        <div class="button_all"><span @click="rejectAllClick">REJECTALL</span><span @click="acceptAllClick">ACCEPTALL</span></div>
        <div class="item_box">
          <div class="item" @click="personalisedClick"><div>Personalised ads and content, ad and content measurement, audience insights and product development</div><div>{{personalisedValue ? 'ON' : 'OFF'}} <i v-if="personalised" class="el-icon-arrow-right"></i> <i v-if="!personalised" class="el-icon-arrow-down"></i></div></div>
          <div class="option" v-if="!personalised">
            <div>Ads and content can be personalised based on a profile. More data can be added to better personalise ads and content. Ad and content performance can be measured. Insights about audiences who saw the ads and content can be derived. Data can be used to build or improve user experience, systems and software.</div>
            <div>
              <el-switch
                  v-model="personalisedValue"
                  active-color="#009cff"
                  inactive-color="#b8b8b8"
                  @change="switchChange"
              >
              </el-switch>
              <span style="margin-left: 4px">{{personalisedValue ? 'ON' : 'OFF'}}</span>
            </div>
          </div>
          <div class="legal" v-if="!personalised" @click="personalisedLegalClick"><i v-if="personalisedLegal" class="el-icon-caret-right"></i><i v-if="!personalisedLegal" class="el-icon-caret-bottom"></i> Legal Description</div>
          <div class="legal_text" v-if="!personalisedLegal">To do basic ad selection vendors can: Use real-time information about the context in which the ad will be shown, to show the ad, including information about the content and the device, such as: device type and capabilities, user agent, URL, IP address. Use a user’s non-precise geolocation data. Control the frequency of ads shown to a user. Sequence the order in which ads are shown to a user. Prevent an ad from serving in an unsuitable editorial (brand-unsafe) context Vendors cannot: Create a personalised ads profile using this information for the selection of future ads without a separate legal basis to create a personalised ads profile. N.B. Non-precise means only an approximate location involving at least a radius of 500 meters is permitted.</div>
        </div>
<!--        <div class="item_box">-->
<!--          <div class="item" @click="storeClick"><div>Store and/or access information on a device</div><div>{{storeValue ? 'ON' : 'OFF'}} <i v-if="store" class="el-icon-arrow-right"></i> <i v-if="!store" class="el-icon-arrow-down"></i></div></div>-->
<!--          <div class="option" v-if="!store">-->
<!--            <div>Cookies, device identifiers, or other information can be stored or accessed on your device for the purposes presented to you.</div>-->
<!--            <div>-->
<!--              <el-switch-->
<!--                  v-model="storeValue"-->
<!--                  active-color="#009cff"-->
<!--                  inactive-color="#b8b8b8"-->
<!--                  @change="storeSwitchChange"-->
<!--              >-->
<!--              </el-switch>-->
<!--              <span style="margin-left: 4px">{{storeValue ? 'ON' : 'OFF'}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="legal" v-if="!store" @click="storeLegalClick"><i v-if="storeLegal" class="el-icon-caret-right"></i><i v-if="!storeLegal" class="el-icon-caret-bottom"></i> Legal Description</div>-->
<!--          <div class="legal_text" v-if="!storeLegal">Vendors can: Store and access information on the device such as cookies and device identifiers presented to a user.</div>-->
<!--        </div>-->
        <div class="item_box">
          <div class="item" @click="specialClick"><div>Special Purposes and Features</div><div><i v-if="special" class="el-icon-arrow-right"></i> <i v-if="!special" class="el-icon-arrow-down"></i></div></div>
          <div style="color: black;margin-top: 15px" v-if="!special">Ensure security, prevent fraud, and debug</div>
          <div v-if="!special">Your data can be used to monitor for and prevent fraudulent activity, and ensure systems and processes work properly and securely.</div>
          <div class="legal" v-if="!special" @click="specialLegalClick"><i v-if="specialLegal" class="el-icon-caret-right"></i><i v-if="!specialLegal" class="el-icon-caret-bottom"></i> Legal Description</div>
          <div class="legal_text" v-if="!specialLegal">To ensure security, prevent fraud and debug vendors can: Ensure data are securely transmitted. Detect and prevent malicious, fraudulent, invalid, or illegal activity. Ensure correct and efficient operation of systems and processes, including to monitor and enhance the performance of systems and processes engaged in permitted purposes Vendors cannot: Conduct any other data processing operation allowed under a different purpose under this purpose. Note: Data collected and used to ensure security, prevent fraud, and debug may include automatically-sent device characteristics for identification, precise geolocation data, and data obtained by actively scanning device characteristics for identification without separate disclosure and/or opt-in.</div>

          <div style="color: black;margin-top: 15px" v-if="!special">Technically deliver ads or content</div>
          <div v-if="!special">Your device can receive and send information that allows you to see and interact with ads and content.</div>
          <div class="legal" v-if="!special" @click="specialLegalClick2"><i v-if="specialLegal2" class="el-icon-caret-right"></i><i v-if="!specialLegal2" class="el-icon-caret-bottom"></i> Legal Description</div>
          <div class="legal_text" v-if="!specialLegal2">To deliver information and respond to technical requests vendors can: Use a user’s IP address to deliver an ad over the internet. Respond to a user’s interaction with an ad by sending the user to a landing page. Use a user’s IP address to deliver content over the internet. Respond to a user’s interaction with content by sending the user to a landing page. Use information about the device type and capabilities for delivering ads or content, for example, to deliver the right size ad creative or video file in a format supported by the device Vendors cannot: Conduct any other data processing operation allowed under a different purpose under this purpose</div>

          <div style="color: black;margin-top: 15px" v-if="!special">Match and combine offline data sources</div>
          <div v-if="!special">Data from offline data sources can be combined with your online activity in support of one or more purposes</div>
          <div class="legal" v-if="!special" @click="specialLegalClick3"><i v-if="specialLegal3" class="el-icon-caret-right"></i><i v-if="!specialLegal3" class="el-icon-caret-bottom"></i> Legal Description</div>
          <div class="legal_text" v-if="!specialLegal3">Vendors can: Combine data obtained offline with data collected online in support of one or more Purposes or Special Purposes.</div>

          <div style="color: black;margin-top: 15px" v-if="!special">Link different devices</div>
          <div v-if="!special">Different devices can be determined as belonging to you or your household in support of one or more of purposes.</div>
          <div class="legal" v-if="!special" @click="specialLegalClick4"><i v-if="specialLegal4" class="el-icon-caret-right"></i><i v-if="!specialLegal4" class="el-icon-caret-bottom"></i> Legal Description</div>
          <div class="legal_text" v-if="!specialLegal4">Vendors can: Deterministically determine that two or more devices belong to the same user or household. Probabilistically determine that two or more devices belong to the same user or household. Actively scan device characteristics for identification for probabilistic identification if users have allowed vendors to actively scan device characteristics for identification (Special Feature 2)</div>

          <div style="color: black;margin-top: 15px" v-if="!special">Receive and use automatically-sent device characteristics for identification</div>
          <div v-if="!special">Your device might be distinguished from other devices based on information it automatically sends, such as IP address or browser type.</div>
          <div class="legal" v-if="!special" @click="specialLegalClick5"><i v-if="specialLegal5" class="el-icon-caret-right"></i><i v-if="!specialLegal5" class="el-icon-caret-bottom"></i> Legal Description</div>
          <div class="legal_text" v-if="!specialLegal5">Vendors can: Create an identifier using data collected automatically from a device for specific characteristics, e.g. IP address, user-agent string. Use such an identifier to attempt to re-identify a device. Vendors cannot: Create an identifier using data collected via actively scanning a device for specific characteristics, e.g. installed font or screen resolution without users’ separate opt-in to actively scanning device characteristics for identification. Use such an identifier to re-identify a device.</div>
        </div>
        <span slot="footer">
          <span class="dialog-footer">
            <el-button type="primary" @click="secondaryDialogClick">SAVE & EXIT</el-button>
          </span>
          <span class="footer_text"><span @click="privacyClick">Privacy Statement</span> <span class="dian"></span> <span @click="cookieClick">Cookie Statement</span> <span class="dian"></span> <span @click="termsClick">Terms of Use</span></span>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import Navigation from './components/Navigation';
import { determinePcOrMove } from '@/utils/utils.js'
import bg from '@/assets/tile.png'
export default {
  name: 'App',
  components: {
    // Navigation
  },
  data() {
    return {
      determinePcOrMove: determinePcOrMove(),
      navHide: true,
      svgBg: {
        backgroundImage: `url(${bg})`
      },
      dialogVisible: localStorage.getItem('personalization') == 1 || this.$route.name == 'privacy' || this.$route.name == 'contactUs' || this.$route.name == 'cookieStatement' || this.$route.name == 'termsOfUse' || this.$route.name == 'NotFound' ? false : true,
      secondaryDialogVisible: false,
      personalised: true,
      personalisedValue: false,
      personalisedLegal: true,
      store: true,
      storeValue: false,
      storeLegal: true,
      special: true,
      specialLegal: true,
      special2: true,
      specialLegal2: true,
      special3: true,
      specialLegal3: true,
      special4: true,
      specialLegal4: true,
      special5: true,
      specialLegal5: true,
    }
  },
  mounted() {
    // 阻止此代码发送广告请求。您必须在使用 adsbygoogle.push(...) 触发任何广告请求之前执行此函数。
    (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=1
    console.log(this.$route);
    const { name } = this.$route
    if (name == 'privacy' || name == 'contactUs') {
      this.navHide = false
    }
  },
  methods: {
    moreClick() {
      this.dialogVisible = false
      this.secondaryDialogVisible = true
    },
    agreeClick() {
      this.dialogVisible = false
      localStorage.setItem('personalization','1');
      // 0同意使用个性化广告 1不同意
      (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds = 0;
      // 继续发送广告请求。
      (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0
    },
    rejectAllClick() {
      this.personalisedValue = false
      this.storeValue = false
    },
    acceptAllClick() {
      this.personalisedValue = true
      this.storeValue = true
    },
    personalisedClick() {
      this.personalised = !this.personalised
    },
    switchChange(value) {
      this.personalisedValue = value
    },
    personalisedLegalClick() {
      this.personalisedLegal = !this.personalisedLegal
    },
    storeClick() {
      this.store = !this.store
    },
    storeSwitchChange(value) {
      this.storeValue = value
    },
    storeLegalClick() {
      this.storeLegal = !this.storeLegal
    },
    specialClick() {
      this.special = !this.special
    },
    specialLegalClick() {
      this.specialLegal = !this.specialLegal
    },
    specialLegalClick2() {
      this.specialLegal2 = !this.specialLegal2
    },
    specialLegalClick3() {
      this.specialLegal3 = !this.specialLegal3
    },
    specialLegalClick4() {
      this.specialLegal4 = !this.specialLegal4
    },
    specialLegalClick5() {
      this.specialLegal5= !this.specialLegal5
    },
    secondaryDialogClick() {
      this.secondaryDialogVisible = false
      localStorage.setItem('personalization','1')
      if (this.personalisedValue) {
        // 0同意使用个性化广告 1不同意
        (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds = 0;
        // 继续发送广告请求。
        (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0
      }
    },
    privacyClick() {
      let pathInfo = this.$router.resolve({
        path: '/privacy',
        query: {
          routeType: 1, // 不走App.vue文件的判断
        }
      })
      window.open(pathInfo.href, '_blank')
    },
    cookieClick() {
      let pathInfo = this.$router.resolve({
        path: '/cookieStatement',
        query: {
          routeType: 1, // 不走App.vue文件的判断
        }
      })
      window.open(pathInfo.href, '_blank')
    },
    termsClick() {
      let pathInfo = this.$router.resolve({
        path: '/termsOfUse',
        query: {
          routeType: 1, // 不走App.vue文件的判断
        }
      })
      window.open(pathInfo.href, '_blank')
    },
  }
}
</script>
<style lang="less">
.el-loading-spinner{
  font-size: 50px;
}
#app{
  height: 100vh;
  overflow-y: auto;
  .el-dialog{
    border-radius: 20px;
    .el-dialog__header{
      padding: 0;
    }
    .el-dialog__body{
      padding: 60px 60px 30px;
      line-height: 24px;
      word-break: break-word;
    }
    .el-dialog__footer{
      box-shadow: 0 0 8px 0 rgba(0,0,0,.4);
      padding: 30px 20px 20px;
    }
    .dialog-footer{
      display: flex;
      justify-content: center;
      font-size: 18px;
      .el-button--default{
        width: 350px;
        border-radius: 20px;
        border: 3px solid #009cff;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.4);
        color: #009cff;
      }
      .el-button--primary{
        width: 350px;
        border-radius: 20px;
        background: #009cff;
        color: white;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.4);
        margin-left: 20px;
      }
      .el-button{
        font-size: 16px;
        font-weight: 700;
      }
    }
    .footer_text{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      font-size: 14px;
      color: #57beff;
      .dian{
        background: #141e23;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50px;
        margin-top: 9px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: auto;
      }
      span{
        cursor: pointer;
      }
    }
  }
  .secondaryDialog{
    .el-dialog__body{
      padding: 20px 30px;
    }
    .button_all{
      text-align: right;
      color: #57beff;
      margin-top: 10px;
      span{
        cursor: pointer;
      }
      span:nth-child(1){
        margin-right: 30px;
      }
    }
    .item_box{
      border-bottom: 1px solid #d8d8d8;
      padding: 10px 0;
      .item{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        div:nth-child(2){
          min-width: 60px;
          margin-left: 30px;
          font-size: 16px;
          text-align: right;
        }
      }
      .option{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        div:nth-child(2){
          min-width: 60px;
          margin-left: 30px;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .legal{
        color: #57beff;
        margin-top: 15px;
        cursor: pointer;
      }
      .legal_text{
        margin-top: 15px;
      }
    }
  }
}
.lFKjX{
  position: absolute;
  inset: -50vh 0px 0px;
  overflow: hidden;
  z-index: -2;
  //transform: translateY(50vh);
  .fzkKCz{
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 100%;
  }
}
</style>
<style>

</style>
