<template>
  <div class="bottom-box">
    <div class="bj">
      <div class="title">MIH5</div>
      <div class="small-title">Play online games on mih5</div>
      <div class="text">
        mih5.COM is one of the best free sites for online games. All games are in real-time, which means you can run these interesting games directly in your browser without downloading, logging, or paying. Above all, all mih5 GAMES are free ! Everyone can play the lasted games and the best games for free!
      </div>
      <div class="small-title">Game selection</div>
      <div class="text">
        mih5 devotes itself to provide games for all ages. For example, you can find fierce types of games such as shooting and driving. Also, there are casual brain-burning games like puzzle and decryption games. Not only that, MMORPGs, strategy, or games that require long hours of operation also can be your options. Besides, we provide a series of unique online games for children. Parents, please trust us, that our website is very faith worthy, 100% safe, and child-friendly.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomIndex",
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>
  .bottom-box{
    padding: 20px 30px;
    margin-bottom: 20px;
    .bj{
      padding: 40px 0 30px;
      background-color: #fe74b2;
      border-radius: 20px;
      //box-shadow: 0 3px 8px 0 rgb(16 0 76 / 60%);
      .title{
        font-size: 27px;
        color: #fff;
        text-align: center;
        margin-bottom: 4px;
      }
      .small-title{
        margin-left: 32px;
        font-size: 20px;
        color: #fff;
        line-height: 1;
        font-weight: 700;
        margin-top: 28px;
      }
      .text{
        padding: 0 50px;
        margin-top: 16px;
        font-size: 16px;
        color: #dde5ff;
        word-break: break-word;
        white-space: normal;
        word-wrap: break-word;
      }
      .bd-link{
        color: #fff81a;
        font-size: 16px;
        padding-left: 18px;
        display: inline-block;
      }
      .bd-link:nth-of-type(1){
        padding-top: 12px;
      }
    }
  }
</style>
