<template>
  <div class="privacy_box">
    <h1><b>Global Privacy Statement</b></h1>
    <h4>Version October 2022</h4>
    <h2>1.  General information</h2>
    <p>Mih5 is committed to protecting the privacy of everyone who uses our websites and applications. The Mih5 websites and applications can be used all over the world. The privacy and data protection requirements vary per country. Regardless of where your personal data is processed, we believe it is important to provide the same high level of protection as described in this privacy statement, which now applies in all countries outside the EU and UK. This privacy statement – the “Global Privacy Statement”, or just in short “Privacy Statement” – is designed to provide transparency into Mih5’s privacy practices and principles. It provides information on the data that we collect from our website visitors through our Mih5.com web platform, our Mih5 for Developers platform, and other general- audience sites and applications (hereafter referred to together as the “Mih5 Website”). Please click
      <a href="https://mih5.com" target="_blank">this link</a> for an overview of the domains referred to as the “Mih5 Website”.</p>
    <p>The Mih5 Website is dedicated to website visitors of 16 years and older (“Website Visitors”). If you are under the age of 16, this website is not meant for you. For our younger players Mih5 has launched a separate website: the
      <a href="https://mih5.com" target="_blank">Mih5 Kids Website</a>. This website is a safe environment for children under the age of 16. To view our privacy practices specific for the Mih5 Kids Website, please refer to the privacy information on the
      <a href="https://mih5.com" target="_blank">Mih5 Kids Website</a>. If you have any questions concerning this Privacy Statement, please contact us at fungames@foxmail.com</p>
    <P>The Mih5 Website is owned and operated by Mih5 B.V. (referred to in this Privacy Statement as “Mih5", “we", “our", or “us"). Mih5 is an entity incorporated under Dutch law.</P>
    <P><i>* Please note that we determine from which region you visit our website based on your IP-address, thus if you use a VPN-connection, you may not be shown the Privacy Statement applicable to you.</i></P>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value = !value"><i v-if="value" class="el-icon-caret-right"></i><i v-if="!value" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">2.  Which data is used and for what purposes?</h2></div>
    <div v-if="!value" class="item" style="margin-bottom: 15px">
      <p>All features on the Mih5 Website are available without the need for registration by Website Visitors. No contact details such as your name, email address and phone number are requested from or processed about Website Visitors, unless they actively contact us. Furthermore, our game developers are in principle required to remove all outgoing links and branding/advertisements (e.g. splash-screens, social links and app-store links) from their games.</p>
      <p>Nonetheless, Mih5 does collect certain electronic data. We can process this data in various ways. Please find directly below some general information about our processing of your data and further below, you find an overview of various topics on which you can click for more detailed information about the processing of your data in a specific context.</p>
      <b>General processing purposes</b>
      <p>In general, we may always process your data for the following purposes:</p>
      <ul>
        <li>For maintenance, administration and network and security purposes;</li>
        <li>For internal control and business operations;</li>
        <li>For analyzing and improving our products;</li>
        <li>For handling any requests, complaints and disputes;</li>
        <li>For determining, exercising and defending our rights; and</li>
        <li>For complying with legal obligations (incl. fraud prevention) and requests of authorized governmental institutions.</li>
      </ul>
      <b>General processing grounds</b>
      <p>In general, we may process your data based on a legal obligation. This means that we will process your data for as far as we are legally obliged to do so, for instance to comply with statutory minimum retention periods.</p>
      <b>Legitimate interest</b>
      <p>Sometimes we indicate that we process your data based on the legal ground "legitimate interest". This means that a balance of interests is performed between the interests that are served by the processing on the one hand and your privacy interests on the other hand, and that the interests in favor of the processing prevail. The related legitimate interests are included below per topic. If you want more information about this, you can contact us directly via our contact details stated below.</p>
      <b>Minors and other persons with a legal representative</b>
      <p>The Mih5 Website is not meant for persons younger than 16 years. We also do not focus on persons who are placed under a legal relationship, such as guardianship or persons who are supervised or administered by someone else. If, in exceptional cases, we could focus on such persons, Mih5 will take into account the vulnerable position of these persons and take extra precautions, such as is the case for our Mih5 Kids Website.</p>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value2 = !value2"><i v-if="value2" class="el-icon-caret-right"></i><i v-if="!value2" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">i. The General Mih5 Website</span></div>
      <div v-if="!value2" class="item">
      <span> We process your data for providing, maintaining and improving the Mih5 Website, our apps and for our social media activities. For more specific information on the cookies and similar technologies used in this respect, please refer to our <a
          href="https://mih5.com" target="_blank">Cookie Statement</a>.</span>
        <p>
          <b>The persons involved.</b>
          Our Website Visitors: people who visit the Mih5 Website.
        </p>
        <p>
          <b>The purpose of the processing.</b>
          When you visit the Mih5 Website, your data may be processed for the following purposes:
        </p>
        <ul>
          <li>Functioning of the Mih5 Website (incl. remembering your settings and consent choices);</li>
          <li>Advertising and marketing to you, targeted advertising, and presenting you with relevant advertisements;</li>
          <li>Showing you the privacy information relevant to your region (which data we process exactly will depend on from which region you access the Mih5 Website).</li>
        </ul>
        <p>
          <b>The data that is processed.</b>
          When you visit the Mih5 Website, we may process the following information about you which is collected automatically:
        </p>
        <ul>
          <li>IP address;</li>
          <li>Device ID;</li>
          <li>Browser type ; </li>
          <li>Global geographic location (e.g. location on national or city level);</li>
          <li>Advertisements displayed to you and your interaction with these (e.g. whether you clicked on an advertisement or not);</li>
          <li>Other technical information (e.g. regarding the interaction between your device and the Mih5 Website, the web pages that were visited, the links clicked and the log data).</li>
        </ul>
        <p><b>Sensitive information:</b></p>
        <p>In the context of the Mih5 Website we do not, in principle, process sensitive information such as information about your health. If we in certain cases would decide to process sensitive information, you will be separately informed about this processing and where necessary, we will request explicit consent.</p>
        <p>
          <b>Legal grounds for the processing.</b>
          We base the use of your data in the context of the Mih5 Website on one of the following legal grounds:
        </p>
        <ul>
          <li>
            <b>Consent.</b>
            Sometimes we base the processing of your data on the (explicit) consent of the relevant person. This is for example applicable for the cookies and similar technologies we only use if you have provided consent for this via a cookie pop-up. For further information on this, please refer to our
            <a href="https://mih5.com" target="_blank"> Cookie Statement.</a>
          </li>
          <li>
            <b>Legitimate interest.</b>
            Our legitimate interest in offering and using the Mih5 Website, apps and social media; and pursuing the other processing purposes listed above.
          </li>
        </ul>
        <p>
          <b>Social media platforms.</b>
          Mih5 uses different social media platforms, for example in the context of recruitment and selection and for marketing purposes. Mih5 is not responsible for the management of the social media platforms, but is responsible for example for content of the Mih5 Website that is shared by Mih5 via a social media platform. For more information about how your data will be used by such social media platforms, we refer to the information already available on the websites of the suppliers of these platforms. Below we have included links to the privacy policies of various suppliers of social media platforms, which we could use:
        </p>
        <ul>
          <li>The <a href="https://www.facebook.com/about/privacy/" target="_blank">privacy policy of Facebook</a>;</li>
          <li>The <a href="https://twitter.com/en/privacy" target="_blank"> privacy policy of Twitter </a>;</li>
          <li>The <a href="https://instagram.com/about/legal/privacy/" target="_blank">privacy policy of Instagram</a>;</li>
          <li>The <a href="https://policies.google.com/privacy" target="_blank">privacy policy of YouTube</a>;</li>
          <li>The <a href="https://www.linkedin.com/legal/privacy-policy?trk=%7Berror-page%7D-privacy-policy" target="_blank">privacy policy of LinkedIn</a>;</li>
          <li>The <a href="https://policies.google.com/privacy" target="_blank">privacy policy of Google</a>;</li>
          <li>The <a href="https://www.redditinc.com/policies/privacy-policy" target="_blank">privacy policy of Reddit</a>;</li>
          <li>The <a href="https://www.tiktok.com/legal/privacy-policy" target="_blank">privacy policy of TikTok</a>;</li>
        </ul>
      </div >
      <div style="display: flex;align-items: center;cursor: pointer" @click="value3 = !value3"><i v-if="value3" class="el-icon-caret-right"></i><i v-if="!value3" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">ii. Mih5 for Developers (secondary domain: developers.Mih5.com and app.Mih5.dev)</span></div>
      <div v-if="!value3" class="item">
        <p>
          <b>The persons involved.</b>
          Game developers that offer their game(s) for the Mih5 Website via the Mih5 for Developers platform with who we have a partnership contract in place.
        </p>
        <p>
          <b>The purpose of the processing.</b>
          When you access our Mih5 for Developers platform, we will process your data for the following purposes:
        </p>
        <ul>
          <li>To provide you with information on the conditions that apply if you wish to provide a game for the Mih5 Website;</li>
          <li>To provide you with a financial overview and related invoices for your account;</li>
          <li>To provide you with information about our SDK (Software Development Kit), such as that you are in principle required to remove all outgoing links and branding/advertisements (e.g. splash-screens, social links and app-store links) from the game.</li>
        </ul>
        <p>
          <b>The data that is processed.</b>
          In case Developers submit a game on our Mih5 for Developers platform, we may process the following information about you:
        </p>
        <ul>
          <li>Full name;</li>
          <li>E-mail address;</li>
          <li>Phone number;</li>
          <li>Skype and/or Discord name;</li>
          <li>Name company/studio;</li>
          <li>Contract details;</li>
          <li>Financial details;</li>
          <li>Game(s) and related details such as game title and description, No. of app installs, App Store URL, web build URL;</li>
          <li>Communication details incl. how you heard about us.</li>
        </ul>
        <p>
          <b>Legal grounds for the processing.</b>
          We base the use of your personal data in the context of developers.Mih5.com on one of the following legal grounds:
        </p>
        <ul>
          <li>
            <b>Contract.</b>
            If you’ve concluded a contract with us, we will process your data in the context thereof.For instance, to make sure you receive the payment due.
          </li>
          <li>
            <b>Legitimate interest.</b>
            Our legitimate interest in providing you with the developers platform and pursuing the other processing purposes mentioned above.
          </li>
        </ul>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value4 = !value4"><i v-if="value4" class="el-icon-caret-right"></i><i v-if="!value4" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">iii. About Mih5 (secondary domain: about.Mih5.com)</span></div>
      <div v-if="!value4" class="item">
        <p>On our “about Mih5” webpage you can find additional information about our company. On this webpage we do not process any additional data about you. On this webpage we do link to some of the above-mentioned webpages, such as developer.Mih5.com or jobs.Mih5.com.</p>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value5 = !value5"><i v-if="value5" class="el-icon-caret-right"></i><i v-if="!value5" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">iv. Mih5’s job application platform (secondary domain: jobs.Mih5.com)</span></div>
      <div v-if="!value5" class="item">
        <p>
          <b>The persons involved.</b>
          People who apply for a job via our designated website.
        </p>
        <p>
          <b>The purpose of the processing.</b>
          When you apply for a job at Mih5 via jobs.Mih5.com, we will process your data for the following purposes:
        </p>
        <ul>
          <li>To assess whether you are a viable candidate for our company;</li>
          <li>To contact you for the further process regarding your application;</li>
          <li>To further process your application.</li>
        </ul>
        <p>
          <b>The data that is processed.</b>
          When you apply for a job via jobs.Mih5.com, we may process the following information about you:
        </p>
        <ul>
          <li>Full name;</li>
          <li>Email address;</li>
          <li>LinkedIn profile;</li>
          <li>Answers to motivation & portfolio questions;</li>
          <li>Resume*;</li>
          <li>Phone number*;</li>
          <li>Current company/employer*;</li>
          <li>How you heard about the job*;</li>
          <li>Additional information such as a cover letter*.</li>
        </ul>
        <p>
          <i>* This information is not mandatory.</i>
        </p>
        <p>
          <b>Legal grounds for the processing.</b>
          We base the use of your data in the context of jobs.Mih5.com on one of the following legal grounds:
        </p>
        <ul>
          <li>
            <b>Consent.</b>
            Sometimes we base the processing of your data on your (explicit) consent. This is for example applicable when you opt-in to the option that Mih5 may contact you during one year after finalization of the application procedure for other future job opportunities that may interest you.
          </li>
          <li>
            <b>Legitimate interest.</b>
            Our legitimate interest in assessing your application and other processing purposes listed above.
          </li>
        </ul>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value6 = !value6"><i v-if="value6" class="el-icon-caret-right"></i><i v-if="!value6" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">v. Mih5 web shop (secondary domain: shop.Mih5.com)</span></div>
      <div v-if="!value6" class="item">
        <p>
          <b>The persons involved.</b>
          People who order products via our web shop.
        </p>
        <p>
          <b>The purpose of the processing.</b>
          When you shop in our web shop, we will process your data for the following purposes:
        </p>
        <ul>
          <li>To process your order and payment;</li>
          <li>To provide you with your order.</li>
        </ul>
        <p>
          <b>The data that is processed.</b>
          When you shop via shop.Mih5.com, we may process the following information about you:
        </p>
        <ul>
          <li>Full name;</li>
          <li>Email address;</li>
          <li>Address;</li>
          <li>Phone number*;</li>
          <li>Payment information (amount, payment method).</li>
        </ul>
        <p>
          <i>* This information is not mandatory.</i>
        </p>
        <p>
          <b>Legal grounds for the processing.</b>
          We base the use of your data in the context of shop.Mih5.com on one of the following legal grounds:
        </p>
        <ul>
          <li>
            <b>Contract.</b>
            We process your data to execute the purchase contract with you: to make sure you receive your order.
          </li>
          <li>
            <b>Legitimate interest.</b>
            We may also process your data in this context based on our legitimate interest involved with the processing purposes mentioned above, such as to determine which of our products are most popular.
          </li>
        </ul>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value7 = !value7"><i v-if="value7" class="el-icon-caret-right"></i><i v-if="!value7" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">vi. Mih5 support</span></div>
      <div v-if="!value7">
        <p>
          <b>The persons involved.</b>
          People who send an email to customer support, e.g. via fungames@foxmail.com.
        </p>
        <p>
          <b>The purpose of the processing.</b>
          When you contact Mih5 customer support or contact Mih5 otherwise, we will process your data for the following purposes:
        </p>
        <ul>
          <li>To provide you with support;</li>
          <li>To answer any question you may have for us.</li>
        </ul>
        <p>
          <b>The data that is processed.</b>
          When you contact Mih5 customer support or contact Mih5 otherwise, we will process the following information about you:
        </p>
        <ul>
          <li>Email address;</li>
          <li>Name (if you provide one);</li>
          <li>The information you sent us and related metadata you shared with us; as well as the information and related metadata we may share with you in reply.</li>
        </ul>
        <p>
          <b>Legal grounds for the processing.</b>
          In principle, we base the use of your data in the context of the Mih5 customer service on the legitimate interest ground. It concerns our legitimate interest to provide you with support and answer any questions you have for us.
        </p>
      </div>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer;" @click="value8 = !value8"><i v-if="value8" class="el-icon-caret-right"></i><i v-if="!value8" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">3.  How do we obtain your data?</h2></div>
    <div v-if="!value8" class="item">
      <p>
        We obtain your data in various ways:
      </p>
      <ul>
        <li>
          <b>Provided by you.</b>
          Some data about you, we receive straight from you. Examples include information in your messages to us and information you enter in our application form.
        </li>
        <li>
          <b>Obtained internally.</b>
          It is possible that we obtain your data via other Mih5 systems. An example for game developers is the data which is included into our CRM system.
        </li>
        <li>
          <b>Obtained from third parties.</b>
          We could also obtain data about you from other persons or external parties. Specifically, we make use of this possibility in two scenarios:
        </li>
        <ul>
          <li style="list-style-type: circle;margin-left: 60px">
            <b>Website Visitors: </b>
            we may receive information about you from privately held third parties, such as for the advertisements that are shown on the Mih5 Website. Further information on this is included in our
            <a href="https://mih5.com" target="_blank">Cookie Statement.</a>
          </li>
          <li style="list-style-type: circle;margin-left: 60px">
            <b>Applicants: </b>
            we may obtain information from a referral included by you in your application.
          </li>
        </ul>
        <li>
          <b>Automatically obtained.</b>
          Some data we obtain automatically, for example by using cookies and similar techniques. When your computer or mobile device contacts our web servers (e.g. when you visit the Mih5 Website), our web servers automatically collect usage information. Such usage information includes information about how our Website Visitors use and navigate the Mih5 Website. It can include the number and frequency of Website Visitors to each web page and the length of their stays, browser type, and referrer data that identifies the web page visited prior and subsequent to visiting the Mih5 Website. Further information on this is included in our
          <a href="https://mih5.com" target="_blank">Cookie Statement.</a>
        </li>
        <li>
          <b>Derived.</b>
          Certain data we do not receive directly, but can be derived from the information already in our possession. For example, information about your language preferences.
        </li>
        <p>In principle you are under no obligation to provide any information about yourself to us. However, refusal to supply certain information could have a negative influence on, for example, the functionality of the Mih5 Website. If the provision of certain personal data is a legal or contractual obligation or an essential requirement for concluding an agreement with us, we will separately provide additional information about this for as far as this is not clear in advance. In such case we will also inform you about the possible consequences if this information is not provided.</p>
      </ul>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value9 = !value9"><i v-if="value9" class="el-icon-caret-right"></i><i v-if="!value9" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">4.  Who do we share your data with?</h2></div>
    <div v-if="!value9" class="item">
      <p>We only share your data with third parties if:</p>
      <ul>
        <li>This is necessary for the provision of a service or the involvement of the third party. Sub-contractors, for example, will in principle only get access to the data that they require for their part of the service provision.</li>
        <li>The persons within the third party that have access to the data are under an obligation to treat your data confidentially. Where necessary this is also contractually agreed on.</li>
        <li>The third party is obliged to comply with the applicable regulations for the protection of personal data, for instance because we have concluded an agreement with this party or because our
          <a href="https://mih5.com" target="_blank">Terms of Use</a> apply. This includes that the party is obliged to ensure appropriate technical and organizational security measures. </li>
      </ul>
      <p>We could share your data on a need-to-know basis with the parties mentioned below. In this context, "need-to-know" means that a party only gets access to your data if and insofar as this is required for the professional services provided by this party.</p>
      <ul>
        <li>Authorized persons, employed by Mih5, who are involved with the processing activity concerned. Such as, the members of the support team you are in contact with.</li>
        <li>Authorized persons, employed by service providers / sub-contractors engaged by Mih5, who are involved with the processing activity concerned. Such as, the service provider for the Mih5 web shop.</li>
        <li>Authorized persons, employed by parties in the private sector with whom we may share certain data, such as our game developers and advertising partners. Traffic information may be shared with game developers and advertisers on an aggregate and anonymous basis. In addition, advertisers on the Mih5 Website may receive information gathered by cookies for targeted advertising purposes. Further information regarding the use of cookies can be found in our
          <a href="https://mih5.com" target="_blank">Cookie Statement</a>.</li>
        <li>Authorized government institutions. Such as, courts, police, and law enforcement agencies. We may release information about our Website Visitors, when legally required to do so, at the request of governmental institutions conducting an investigation or to verify or enforce compliance with the policies governing the Mih5 Website and applicable law. We may also disclose such user information whenever we believe disclosure is necessary to protect the rights, property or safety of Mih5, or any of our respective business partners, customers or others.</li>
        <li>Aggregate Information. We may also disclose non-identifying, aggregated user statistics to third parties for a variety of purposes, including describing our services to prospective partners and other third parties. Examples of such non-personal information include the number of users who visited this Mih5 Website during a specific time period or played a specific game.</li>
      </ul>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value10 = !value10"><i v-if="value10" class="el-icon-caret-right"></i><i v-if="!value10" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">5.  How do we secure your data?</h2></div>
    <div v-if="!value10" class="item">
      <p>
        Protecting your privacy and data is very important to us. Therefore, Mih5 has implemented appropriate technical and organizational measures to protect and secure personal data, in order to prevent violations of the confidentiality, integrity and availability of the data. All Mih5 employees and other persons engaged by Mih5 for the processing of data are obliged to respect the confidentiality of personal data.
      </p>
      <p>
        Mih5 has internal documentation in which it is described how we safeguard an appropriate level of technical and organizational security. In addition, a data breach procedure is applicable within Mih5, in which it is explained how (potential) data breaches need to be handled. We will, for example, inform the competent supervisory authority and involved data subjects when this is required by the applicable law.
      </p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value11 = !value11"><i v-if="value11" class="el-icon-caret-right"></i><i v-if="!value11" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">6.  To which countries will we transfer your data?</h2></div>
    <div v-if="!value11" class="item">
      <p>
        Parties involved with the processing of your data may be located in a different country than you are. No matter where you are located, we are securing your personal data as much as reasonably possible.
      </p>
      <p>
        If your data is transferred from the EU to a party located outside the EU, we call this an '<b>EU transfer</b>' (see
        <a href="https://ind.nl/en/member-states-eu-eea" target="_blank">this link</a> for an overview of the EU countries). If your data is transferred from the UK to a party located outside the UK, we call this a '<b>UK transfer</b>'. EU transfers and UK transfers are legitimized in the manner described below. You may contact us for information on the legitimization of other data transfers.
      </p>
      <p>
        <b>EU transfers.</b> An EU transfer EUcan in the first place be legitimized based on an adequacy decision of the European Commission, in which it is decided that the (part within the) third country in question ensures an adequate level of data protection. See
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_nl" target="_blank">this link</a> for a summary of the applicable adequacy decisions.
      </p>
      <p>
        UK transfers. A UK transfer can in the first place be legitimized based on an adequacy regulation of the UK government. See
        <a href="https://www.gov.uk/government/publications/uk-approach-to-international-data-transfers/international-data-transfers-building-trust-delivering-growth-and-firing-up-innovation" target="_blank">this link</a> for an overview of the applicable adequacy regulations.
      </p>
      <p>If an EU transfer and/or UK transfer takes place for which there is no adequacy decision or adequacy regulation in place, we agree on the applicability of the relevant version of the Standard Contractual Clauses with the relevant party. This is a standard contract to safeguard the protection of your data, which is approved by the European Commission in which the parties fill out the appendices. See
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_nl" target="_blank">this link</a> for the various versions of the Standard Contractual Clauses for the EU. Where appropriate, additional safeguards should be taken. Where the UK GDPR is applicable, a UK Addendum will be added to the Standard Contractual Clauses mentioned above, as required by UK law and regulations (see
        <a href="https://ico.org.uk/media/for-organisations/documents/4019539/international-data-transfer-addendum.pdf" target="_blank">this link</a>). </p>
      <p>
        In specific situations we can also rely on the derogations as specified in applicable law to legitimize the EU transfer and/or UK data transfer. This could mean that we may transfer your data: (i) with your explicit consent, (ii) if this is necessary for the performance of a contract that has been concluded with you or has been concluded in your interest, or (iii) if this is necessary for the establishment, exercise or defense of legal claims. Lastly, in exceptional cases we may also transfer your data if the data transfer is necessary for our compelling legitimate interests and is not overridden by your interests or rights and freedoms.
      </p>
      <p>
        You can contact us if you want additional information via our contact details as stated at the bottom of this Privacy Statement.
      </p>
      <p>
        <b>Google Analytics Blockage</b>
        Blockage Please note that we do not use Google Analytics on any of our websites. However, it is possible that one of the game developers we work with tries to use Google Analytics for tracking in-game activities. We actively try to find these implementations and overwrite them for users from the EU. To opt out of being tracked by Google Analytics across all websites, please visit:
        <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">http://tools.google.com/dlpage/gaoptout.</a>
      </p>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value12 = !value12"><i v-if="value12" class="el-icon-caret-right"></i><i v-if="!value12" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">7.  How do we determine how long we retain your data?</h2></div>
    <div v-if="!value12" class="item">
      <p>In general, we do not keep your data for longer than what is necessary in relation to the purposes for which we process the data. There could however be exceptions applicable to the general retention terms.</p>
      <p>
        <b>Exception: shorter retention period.</b>
        If you exercise certain privacy rights, it is possible that Mih5 will remove your data earlier than the general applicable retention period or – oppositely – retain it for a longer period of time. For more information about this, please refer to the header "What are your privacy rights (incl. right to object)?"
      </p>
      <p>
        <b>Exception: longer retention period.</b>
        In certain situations, we process your data for a longer period of time than what is necessary for the purpose of the processing. This is for instance the case when we have to process your data for a longer period of time:
      </p>
      <ul>
        <li>
          <b>Retention obligation.</b>
          To comply with a minimum retention period or other legal obligation to which we are subject based on the applicable law;
        </li>
        <li>
          <b>Procedure.</b>
          Your personal data is necessary in relation to a legal procedure;
        </li>
        <li>
          <b>Freedom of expression.</b>
          When further processing of your personal data is necessary in order to exercise the right to freedom of expression and information.
        </li>
      </ul>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value13 = !value13"><i v-if="value13" class="el-icon-caret-right"></i><i v-if="!value13" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">8.  What are your privacy rights (incl. right to object)?</h2></div>
    <div v-if="!value13" class="item">
      <p>You have various privacy rights. Which privacy rights you have, depends on the applicable law. To what extent you can exercise these rights may depend on the circumstances of the processing, such as the manner in which Mih5 processes the personal data and the legal ground for the processing. Below, we included a summary of the privacy rights you may have. The applicable law may allow or require us to refuse your request, because these rights are not absolute or may not be applicable in your region.</p>
      <p>For more information about your privacy rights in the EU and the UK, go to <a href="https://europa.eu/youreurope/citizens/consumers/internet-telecoms/data-protection-online-privacy/index_en.htm" target="_blank">this webpage</a> or
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens/my-rights_en" target="_blank">this webpage</a> of the European Commission or
        <a href="https://ico.org.uk/your-data-matters/" target="_blank">this webpage</a> of the UK Information Commissioner’s Office.</p>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value14 = !value14"><i v-if="value14" class="el-icon-caret-right"></i><i v-if="!value14" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">8.1  Your privacy rights.</span></div>
      <div v-if="value14" class="item">
        <div>In relation to our processing of your personal data, you may have the below privacy rights.</div>
        <ol type="a">
          <li>
            <b>Right to withdraw consent</b>
            . In so far as our processing of your data is based on your consent, you have the right to withdraw your consent at any time via our contact details stated below. Withdrawal of consent does not influence the legitimacy of the processing before you withdrew your consent. If you withdraw your consent, Mih5 will no longer process your data for the purpose that you consented to. It can however be possible that we still process the personal data for another purpose, such as to comply with a minimum retention period. In that case you will be informed about this.
          </li>
          <li>
            <b>Right of access.</b>
            You may have the right to request access to your data. This enables you to receive a copy of the data we hold about you (but not necessarily the documents themselves). We will then also provide you with further information on our processing of your personal data. For example, the purposes for which we process your data, where we got it from, and with whom we share it. Where applicable, we may refer to this Privacy Statement.
          </li>
          <li>
            <b>Right to rectification.</b>
            You may have the right to request rectification of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected. You have this right in case we process personal data about you that: (i) is factually incorrect; (ii) is incomplete or not related to the purpose it was collected for; or (iii) is in any other way used in a manner that is in conflict with an applicable law.
            <br>
            The right of rectification is not intended for the correction of professional opinions, findings or conclusions that you do not agree with. However, Mih5 could in such case consider adding your opinion about this to your data.
          </li>
          <li>
            <b>Right to erasure.</b>
            You may have the right to request erasure of your personal data. This enables you to ask us to delete or remove your data where: (i) the data is no longer necessary, (ii) you have withdrawn your consent, (iii) you have objected to the processing activities, (iv) the data has been unlawfully processed, (v) the data has to be erased on the basis of a legal requirement, or (vi) where the data has been collected in relation to the offer of information society services.
            <br>
            However, we do not have to honor your request to the extent that the processing is necessary: (i) for exercising the right of freedom of expression and information, (ii) for compliance with a legal obligation which requires processing, (iii) for reasons of public interest in the area of public health, (iv) for archiving purposes, or (v) for the establishment, exercise or defense of legal claims.
          </li>
          <li>
            <b>Right to object.</b>
            You may have the right to object to the processing of your data where we are relying on legitimate interest as processing ground (see above). Insofar as the processing of your data takes place for direct marketing purposes, we will always honor your request. For processing for other purposes, we will also cease and desist processing, unless we have compelling legitimate grounds which override your interests, rights and freedoms or that are related to the institution, exercise or substantiation of a legal claim. If such is the case, we will inform you on our compelling grounds and the balance of interests made.
          </li>
          <li>
            <b>Right to restriction</b>
            . If applicable, the right to restriction of processing means that Mih5 will continue to store personal data at your request but may in principle not do anything further with it. In short, you have this right when Mih5 does not have (or no longer has) any legal ground for the processing of your data or if this is under discussion. This right is specifically applicable in the following situations:
            <br>
            <b>Unlawful processing</b>
            . We may not (or no longer) process certain personal data, but you do not want us to erase the data. For example, because you still want to request the data at a later stage.
            <br>
            <b>Data no longer required</b>
            . Mih5 no longer needs your data for our processing purposes, but you still require the personal data for a legal claim. For example, in case of a dispute.
            <br>
            <b>Pending an appeal</b>
            . You objected against the processing of your data by Mih5 (see the right to object above). Pending the verification of your appeal we will no longer process this personal data at your request.
            <br>
            <b>Contesting the accuracy of data</b>
            . You contest the accuracy of certain data that we process about you (e.g. via your right to rectification; see above). During the period in which we assess your contest we will no longer process this personal data at your request.
          </li>
          <li>
            <b>Right to data portability.</b>
            You may have the right to request the transfer of your data to you or to a third party of your choice (right to data portability). We will provide you, or such third party, with your data in a structured, commonly used, machine-readable format. Please note that this right only applies if it concerns processing that is carried out by us by automated means, and only if our processing ground for such processing is your consent or the performance of a contract to which you are a party (see above).
          </li>
          <li>
            <b>Automated decision-making.</b>
            You may have the right not to be subject to a decision based solely on automated processing, which significantly impacts you (“which produces legal effects concerning you or similarly significantly affects you”). In this respect, please be informed that when processing your personal data, we do not make use of automated decision-making which significantly impacts you.
          </li>
          <li>
            <b>Right to complaint.</b>
            In addition to the above-mentioned rights, you may have the right to lodge a complaint with a supervisory authority, in particular in the country of your habitual residence, place of work or where an alleged infringement took place. However, we would appreciate the chance to deal with your concerns before you approach them, so please contact us beforehand.
          </li>
        </ol>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value15 = !value15"><i v-if="value15" class="el-icon-caret-right"></i><i v-if="!value15" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">8.2  How to exercise your rights.</span></div>
      <div v-if="value15" class="item">
        You can exercise your privacy rights free of charge, by phone or by e-mail via the contact details displayed below. If requests are manifestly unfounded or excessive, in particular because of the repetitive character, we may either charge you a reasonable fee or refuse to comply with the request.
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value16 = !value16"><i v-if="value16" class="el-icon-caret-right"></i><i v-if="!value16" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">8.3  Verification of your identity.</span></div>
      <div v-if="value16" class="item">
        We may request specific information from you to help us confirm your identity before we further respond to your privacy request.
      </div>
      <div style="display: flex;align-items: center;cursor: pointer" @click="value17 = !value17"><i v-if="value17" class="el-icon-caret-right"></i><i v-if="!value17" class="el-icon-caret-bottom"></i><span style="margin-left: 5px">8.4  Follow-up of your requests.</span></div>
      <div v-if="value17" class="item">
        We will provide you with information about the follow-up of the request without undue delay and in principle within one month of receipt of the request. Depending on the complexity of the request, the number of requests and the applicable law, this period can be extended. We will notify you of such an extension within one month of receipt of the request. The applicable law may allow or require us to refuse your request. If we cannot comply with your request, we will inform you of the reasons why, subject to any legal or regulatory restrictions.
      </div>
    </div>
    <div style="display: flex;align-items: center;cursor: pointer" @click="value18 = !value18"><i v-if="value18" class="el-icon-caret-right"></i><i v-if="!value18" class="el-icon-caret-bottom"></i><h2 style="margin: 0 0 0 5px;">9.  Who is responsible for the processing of your data?</h2></div>
    <div v-if="!value18" class="item">
      <p>Mih5 is in principle responsible for the processing of your data in the context of the Mih5 Website, but other parties may also be involved and responsible themselves, as specified below.</p>
      <p>
        <b>9.1  Developers.</b>
        <br>
        The developers of the games on the Mih5 Website are in principle required to remove all outgoing links and branding/advertisements (e.g. splash-screens, social links and app-store links) from their game(s). If developers do gather personal data for their own purposes, Mih5 is not responsible for this processing: only the developer decides what (personal) data is processed and for what purpose. However, because Mih5 feels it is important that your data is also safe when you play a game, we took two measures. In the first place, we included contractual safeguards with the developers. They are only allowed to include cookies and similar technologies in their game if no consent for this is required under the applicable law. In the second place, we have prepared
        <a href="https://mih5.com" target="_blank">this list</a> of the cookies used by our game developers, categorized per game, to inform you on behalf of the developers.* For further information on developers cookies, please refer to our
        <a href="https://mih5.com" target="_blank">Cookie Statement</a>. Furthermore, if you notice any possible undue processing by a developer of your data, please inform us so that we can investigate.
      </p>
      <p>
        <b>9.2   Advertisement partners.</b>
        <br>
        Mih5 uses the services of several advertising parties. These parties provide us with advertisements for the Mih5 Website and are responsible for their part of the processing of your data in this context. To nevertheless safeguard your privacy in this respect as much as possible, Mih5 has, amongst other things, ensured that suitable contractual measures are in place. Further information regarding the use of cookies, also in the context of advertising partners, can be found in our
        <a href="https://mih5.com" target="_blank">Cookie Statement</a>.
      </p>
      <p>
        <b>9.3   Externally hosted games, third party games and other websites.</b>
        <br>
        This Privacy Statement does not apply to usage of your data by other parties that are themselves responsible for such usage of your data, such as providers of third-party games available on the Mih5 Website. The relevant provider of such third-party game is responsible for ensuring that any processing of your data by them in the context of a game complies with the applicable law. In principle, Mih5 does not permit third party content providers to collect personal data about Website Visitors. However, if any personal data will be collected by a third-party content provider, Mih5 will require that the processing of such data by them complies with the applicable law.
      </p>
      <p>
        <i>* Please note that this list may not be complete, since randomized cookies that are placed with less than 100 Website Visitors are not included in the list. Furthermore, the cookies used by developers may change over time. Therefore, we automatically update this list daily.</i>
      </p>
    </div>
    <h2>10.  How can you contact us?</h2>
    <p>If you have any questions concerning this Privacy Statement, or our usage of your data, please contact us at <b>fungames@foxmail.com</b></p>
    <p>Please let us know by e-mail in advance if you prefer to have further contact over the phone in another language. We will then try to facilitate your request.</p>
    <h2>11.  Changes</h2>
    <p>
      We may change this Privacy Statement from time to time to accommodate new technologies, industry practices, regulatory requirements or for other purposes. The latest version can always be consulted via the Mih5 Website. Important changes will also be communicated to you.
    </p>
  </div>
</template>

<script>
export default {
  name: "privacyIndex",
  components: {
  },
  data() {
    return {
      value: true,
      value2: true,
      value3: true,
      value4: true,
      value5: true,
      value6: true,
      value7: true,
      value8: true,
      value9: true,
      value10: true,
      value11: true,
      value12: true,
      value13: true,
      value14: true,
      value15: true,
      value16: true,
      value17: true,
      value18: true,
    }
  }
}
</script>

<style lang="less" scoped>
.privacy_box{
  width: 100%;
  height: 100vh;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
  h1{
    font-size: 24px;
    line-height: 1.875;
    margin: 0px 0px 0.5em;
  }
  h4{
    font-size: 16px;
    line-height: 1;
    color: #5d6b84;
    margin: 1em 0px;
  }
  h2{
    font-size: 20px;
    line-height: 1.25;
    color: #009cff;
    margin: 1em 0px;
  }
  p{
    margin: 1em 0px;
    color: #002b50;
  }
  a{
    text-decoration: none;
    color: #009cff;
  }
  ul,ol{
    margin: 0.5em 0px;
    li{
      margin-left: 20px;
    }
  }
  .item{
    li{
      margin-left: 40px;
    }
  }
}
</style>
